import {ReactNode} from "react";
import {PlusCircleIcon} from "@heroicons/react/20/solid";

export default function TabbedPanel({tabs, selectedTab, setTab, children, onNew} : {
    tabs:string[],
    selectedTab:string,
    setTab:(newTab:string) => void,
    onNew?: () => void,
    children:ReactNode
}) {
    return <div>
        <div className="mb-6">
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                <select
                    id="tabs"
                    name="tabs"
                    defaultValue={selectedTab}
                    className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                >
                    {tabs.map((tab) => (
                        <option key={tab}>{tab}</option>
                    ))}
                </select>
            </div>
            <div className="hidden sm:block">
                <div className="border-b border-gray-300 px-6">
                    <nav aria-label="Tabs" className="flex space-x-4">
                        {tabs.map((tab) => (
                            <span
                                key={tab}
                                onClick={() => setTab(tab)}
                                aria-current={tab === selectedTab ? 'page' : undefined}
                                className={
                                    (tab === selectedTab
                                        ? 'bg-blue-100 text-blue-700 '
                                        : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100 ')+
                                    'rounded-t-md px-3 py-2 text-sm font-medium hover:cursor-pointer'}
                            >{/*(tab === selectedTab
                                        ? 'border-blue-500 text-blue-600 '
                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 ')+
                                    'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium hover:cursor-pointer'*/}
                                {tab}
                            </span>
                        ))}
                        {onNew === undefined ? <></> :
                            <span onClick={onNew} className="whitespace-nowrap text-gray-500 border-transparent border-b-2 px-1 py-2 text-sm font-medium hover:cursor-pointer hover:text-blue-600">
                                <PlusCircleIcon aria-hidden="true" className="h-5 w-5"/>
                            </span>
                        }
                    </nav>
                </div>
            </div>
        </div>
        {children}
    </div>
}
