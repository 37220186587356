import Page from "../ui/Page";
import {Button, Header, IconOutlineButton} from "../ui/Base";
import {ReactNode, useEffect, useState} from "react";
import {EmailData, InboxRecord, ReplyInfo} from "../client/Client";
import {createSearchParams, useNavigate} from "react-router-dom";
import {ReplyTo} from "./Send";
import {useClient} from "../client/AuthClient";
import "../ui/LoadingShimmer.css";
import {formatShortDateTime} from "../ui/Utils";
import {
    ArrowPathIcon,
    BoltIcon,
    EllipsisVerticalIcon,
    EyeIcon,
    PaperAirplaneIcon
} from "@heroicons/react/20/solid";
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/react";
import {SingleLinePlaceholder} from "../ui/Placeholders";
import Address from "../ui/Address";
import {useInfiniteQuery, useMutation} from "@tanstack/react-query";
import React from "react";

export function ToolTipBadge ({text, className, tipClassName, children}: {text: string, className: string, tipClassName: string, children: ReactNode}) {
    const [hidden, setHidden] = useState(true);

    return <div className="inline-block relative" onMouseEnter={() => setHidden(false)} onMouseLeave={() => setHidden(true)}>
        <div className={"rounded-md px-3 py-0.5 mx-2 cursor-default " +className}>{text}</div>
        <div className={"absolute z-10 min-w-64 p-4 rounded " + (hidden?"hidden ":"")+ (tipClassName !== undefined ? tipClassName: "")}>
            {children}
        </div>
    </div>
}

function replyLabel(text: String) {
    return <span className="font-bold">{text}</span>
}

export function getReplyInfo(messageId: string, replyInfo: ReplyInfo | null, goToMessage: (messageId: string) => void) {
    if (replyInfo === null || replyInfo.replyStatus === "UNSCANNED") {
        return <div className="inline-block bg-gray-200 text-gray-600 rounded-md px-3 py-0.5 mx-2 font-semibold cursor-default">Unscanned</div>
    } else if (replyInfo.replyStatus === "NO_ACTION") {
        return <div className="inline-block bg-white text-slate-500 border-2 border-slate-400 rounded-md px-3 py-0.5 mx-2 font-semibold cursor-default">No action</div>
    } else if (replyInfo.replyStatus === "ERROR") {
        return <div className="inline" onClick={() => goToMessage(messageId)}>
            <ToolTipBadge text="Error" className="bg-red-300 text-red-900 font-semibold"
                          tipClassName="bg-red-100 border border-red-400 text-left origin-top-right right-0">
                {replyLabel("Action: ")}{replyInfo.actionName}<br/>
                {replyLabel("ActionSet: ")}{replyInfo.actionSet}<br/>
                {replyLabel("Input:")}<br/>
                <pre>{JSON.stringify(replyInfo.extractedData, null, 2)}</pre>
                {replyLabel("Error:")}
                <pre>
                        {replyInfo.error}
                    </pre>
            </ToolTipBadge>
        </div>
    } else if (replyInfo.replyStatus === "FOLLOWUP") {
        return <div className="inline" onClick={() => goToMessage(replyInfo?.messageId)}>
            <ToolTipBadge text="Follow Up" className="bg-yellow-100 text-yellow-900 font-semibold"
                          tipClassName="bg-yellow-50 border border-yellow-500 text-left origin-top-right right-0">
                <>
                    {replyLabel("Action: ")}{replyInfo.actionName}<br/>
                    {replyLabel("ActionSet: ")}{replyInfo.actionSet}<br/>
                    {replyLabel("Input:")}<br/>
                    <pre>{JSON.stringify(replyInfo.extractedData, null, 2)}</pre>
                </>
            </ToolTipBadge>
        </div>
    } else if (replyInfo.replyStatus === "REPLIED") {
        return <div className="inline" onClick={() => goToMessage(replyInfo?.messageId)}>
            <ToolTipBadge text="Replied" className="bg-blue-100 text-blue-800 font-semibold"
                          tipClassName="bg-blue-100 border border-blue-400 text-left origin-top-right right-0">
                <>
                    {replyLabel("Action: ")}{replyInfo.actionName}<br/>
                    {replyLabel("ActionSet: ")}{replyInfo.actionSet}<br/>
                    {replyLabel("Input:")}<br/>
                    <pre>{JSON.stringify(replyInfo.extractedData, null, 2)}</pre>
                    {replyLabel("Output:")}<br/>
                    <pre>{JSON.stringify(replyInfo.outputData, null, 2)}</pre>
                </>
            </ToolTipBadge>
        </div>
    } else if (replyInfo.replyStatus === "ACTION_AWAIT") {
        return <div className="inline" onClick={() => goToMessage(replyInfo?.messageId)}>
            <ToolTipBadge text="Async" className="bg-purple-100 text-purple-800 font-semibold"
                          tipClassName="bg-purple-100 border border-purple-400 text-left origin-top-right right-0">
                <>
                    {replyLabel("Action: ")}{replyInfo.actionName}<br/>
                    {replyLabel("ActionSet: ")}{replyInfo.actionSet}<br/>
                    {replyLabel("Input:")}<br/>
                    <pre>{JSON.stringify(replyInfo.extractedData, null, 2)}</pre>
                    {replyLabel("Action is awaiting completion.")}
                </>
            </ToolTipBadge>
        </div>
    } else if (replyInfo.replyStatus === "NO_ACTION") {
        return <></>
    }
    return <></>
}

function MailViewerActionMenu(props: {emailData: EmailData, inboxRecord? : InboxRecord}) {
    const navigate = useNavigate();
    const {client} = useClient();

    const scanEmailQuery = useMutation({
        mutationKey: ['Message', props.emailData.messageId],
        mutationFn: () => client.TriggerScan({messageId: props.emailData.messageId})
    });

    function replyToMessage(emailData: EmailData, inboxRecord?: InboxRecord) {
        let replyInfo: ReplyTo = {
            messageId : emailData.messageId,
            to: emailData.from.email
        }
        if (inboxRecord !== undefined) {
            replyInfo.subject = "Re: " + inboxRecord.subject
        }
        navigate({
            pathname: "/send",
            search: createSearchParams({
                reply: btoa(JSON.stringify(replyInfo))
            }).toString()
        });
    }

    function goToMessage() {
        navigate({
            pathname: "/message",
            search: createSearchParams({
                messageId: btoa(props.emailData.messageId)
            }).toString()
        });
    }

    return <Menu as="div" className="relative inline-block text-left">
        <div>
            <MenuButton>
                <span className="sr-only">Open email options</span>
                <IconOutlineButton className="text-gray-500 border-gray-300 mt-0.5"><EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5"/></IconOutlineButton>
            </MenuButton>
        </div>

        <MenuItems
            transition
            className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
            <div className="py-1">
                <MenuItem>
                        <span
                            onClick={() => goToMessage()}
                            className="group flex items-center px-4 py-2 text-sm text-gray-700 hover:cursor-pointer data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:forced-color-adjust-none data-[focus]:forced-colors:bg-[Highlight] data-[focus]:forced-colors:text-[HighlightText]"
                        >
                            <EyeIcon
                                aria-hidden="true"
                                className="mr-3 h-5 w-5 inline text-gray-400 group-data-[focus]:text-gray-500"
                            />
                            View
                        </span>
                </MenuItem>
            </div>
            <div className="py-1">
                <MenuItem>
                        <span
                            onClick={() => replyToMessage(props.emailData, props.inboxRecord)}
                            className="group flex items-center px-4 py-2 text-sm text-gray-700 hover:cursor-pointer data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:forced-color-adjust-none data-[focus]:forced-colors:bg-[Highlight] data-[focus]:forced-colors:text-[HighlightText]"
                        >
                            <PaperAirplaneIcon
                                aria-hidden="true"
                                className="mr-3 h-5 w-5 inline text-gray-400 group-data-[focus]:text-gray-500"
                            />
                            Reply
                        </span>
                </MenuItem>
            </div>
            {props.emailData.replyInfo !== undefined && props.emailData.replyInfo !== null ? <></> :
                <div className="py-1">
                    <MenuItem>
                        <span
                            onClick={() => scanEmailQuery.mutate()}
                            className="group flex items-center px-4 py-2 text-sm text-gray-700 hover:cursor-pointer data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:forced-color-adjust-none data-[focus]:forced-colors:bg-[Highlight] data-[focus]:forced-colors:text-[HighlightText]"
                        >
                            <BoltIcon
                                aria-hidden="true"
                                className="mr-3 h-5 w-5 inline text-gray-400 group-data-[focus]:text-gray-500"
                            />
                            Scan
                        </span>
                    </MenuItem>
                </div>
            }
            {props.emailData.replyInfo === undefined || props.emailData.replyInfo === null || props.emailData.replyInfo.replyStatus !== "ERROR" ? <></> :
                <div className="py-1">
                    <MenuItem>
                        <span
                            onClick={() => scanEmailQuery.mutate()}
                            className="group flex items-center px-4 py-2 text-sm text-gray-700 hover:cursor-pointer data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:forced-color-adjust-none data-[focus]:forced-colors:bg-[Highlight] data-[focus]:forced-colors:text-[HighlightText]"
                        >
                            <ArrowPathIcon
                                aria-hidden="true"
                                className="mr-3 h-5 w-5 inline text-gray-400 group-data-[focus]:text-gray-500"
                            />
                            Retry
                        </span>
                    </MenuItem>
                </div>
            }
        </MenuItems>
    </Menu>
}

export function MailViewer() {
    const navigate = useNavigate();
    const {client} = useClient();

    const [inboxRecords, setInboxRecords] = useState<Record<string, InboxRecord[]>>({});

    const emailsInfiniteQuery = useInfiniteQuery<{
        emailData: EmailData[],
    }>({
        queryKey: ['inbox'],
        queryFn: ({pageParam = ""}) => client.GetEmailRecords({
                before: ""+pageParam,
            }),
        initialPageParam: "",
        getNextPageParam: (lastPage) => lastPage.emailData.length === 0 ? undefined : Math.min(...lastPage.emailData.map((email) => email.timestamp))
    });

    useEffect(() => {
        emailsInfiniteQuery.fetchNextPage();
    }, []);

    const getRecordQuery = useMutation({
        mutationKey: ["getRecord"],
        mutationFn: (data: {page: string, messages: string[]}) => client.GetInboxRecords({
            MessageIDs: data.messages
        }),
        onSuccess: (result, variables) => {
            setInboxRecords((prev) => {
                const newVal: Record<string, InboxRecord[]> = {};
                newVal[variables.page] = result.InboxRecords;
                return {...prev, ...newVal}
            })
        }
    });

    useEffect(() => {
        if (emailsInfiniteQuery.data !== undefined && (getRecordQuery.status === "idle" || getRecordQuery.status === "success")) {
            for (let i=0; i < emailsInfiniteQuery.data.pageParams.length; i++) {
                if (!(""+emailsInfiniteQuery.data.pageParams[i] in inboxRecords)) {
                    getRecordQuery.mutate({page: ""+emailsInfiniteQuery.data.pageParams[i], messages: emailsInfiniteQuery.data.pages[i].emailData.map((email) => email.messageId)})
                    break;
                }
            }
        }
    }, [emailsInfiniteQuery.data?.pageParams, inboxRecords]);

    const [indexedInboxRecords, setIndexedInboxRecords] = useState<Record<string, InboxRecord>>({});
    useEffect(() => {
        let newData : Record<string, InboxRecord>= {};
        for (const pageLabel in inboxRecords) {
            for (const message of inboxRecords[pageLabel]) {
                newData[message.messageId] = message;
            }
        }
        setIndexedInboxRecords(newData);
    }, [inboxRecords]);

    function goToMessage(messageId: string) {
        navigate({
            pathname: "/message",
            search: createSearchParams({
                messageId: btoa(messageId)
            }).toString()
        });
    }

    return <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                    <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-3">
                            Received
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            From
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Subject
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                            Actions
                        </th>
                    </tr>
                    </thead>
                    <tbody className="bg-white">
                    {emailsInfiniteQuery.data?.pages.map((page, pageNum) =>
                        <React.Fragment key={pageNum}>
                            {page.emailData.map((email, index) => {
                                let inboxRecord = indexedInboxRecords[email.messageId];

                                return <tr key={email.messageId} className="even:bg-gray-50">
                                    <td className="whitespace-nowrap py-1 pl-4 pr-3 text-sm font-medium text-gray-900 w-[100px] sm:pl-3">
                                        {formatShortDateTime(email.timestamp)}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-600">
                                        <div className="inline-block max-w-[150px] overflow-x-clip"><Address
                                            address={email.from}/></div>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-700 hover:cursor-pointer"
                                        onClick={() => goToMessage(email.messageId)}>
                                        <div className="inline-block min-w-[500px] overflow-clip">{(inboxRecord !== undefined) ? inboxRecord.subject : <SingleLinePlaceholder/>}</div>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-1 text-sm w-[150px]">
                                        <div className="flex items-center">
                                            <div className="flex-grow"/>
                                            {getReplyInfo(email.messageId, email.replyInfo, goToMessage)}
                                            <MailViewerActionMenu emailData={email}/>
                                        </div>
                                    </td>
                                </tr>
                            })}
                        </React.Fragment>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
        <div className="pt-2 text-center border-t border-gray-300">
            <Button disabled={!emailsInfiniteQuery.hasNextPage} onClick={() => emailsInfiniteQuery.fetchNextPage()}>Load More</Button>
        </div>
    </div>;
}


export default function Inbox () {

    return <Page title="Inbox">
        <Header>Inbox</Header>
        <div className="mt-8">
            <MailViewer/>
        </div>
    </Page>
}