import {ParsedAddress} from "../client/Client";
import {useState} from "react";

export default function Address({address, className, tipClassName} : {address: ParsedAddress, className?: string, tipClassName?: string}) {
    const [hidden, setHidden] = useState(true);

    return <div className="inline-block relative" onMouseEnter={() => setHidden(false)} onMouseLeave={() => setHidden(true)}>
        <span className={"cursor-default " + (className === undefined ? "" : className)}>{address.email}</span>
        {address.displayName === null ? <></> :
            <div className={"absolute z-10 min-w-64 p-2 rounded bg-blue-50 " + (hidden?"hidden ":"")+ (tipClassName !== undefined ? tipClassName: "")}>
                {address.displayName}
            </div>
        }
    </div>
}
