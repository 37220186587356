import {DropDown, Header, MutationButton, Section, TextArea, TextInput} from "../ui/Base";
import {useEffect, useState} from "react";
import Page from "../ui/Page";
import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";
import {useClient} from "../client/AuthClient";
import {SingleLinePlaceholder} from "../ui/Placeholders";
import {useMutation, useQuery} from "@tanstack/react-query";

export interface ReplyTo {
    messageId: string,
    to: string,
    subject?: string,
}

function parseReply(param: string | null) : ReplyTo | undefined {
    return (param === null ? undefined : JSON.parse(atob(param)));
}

export default function Send() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const {client} = useClient();

    const [actionSets, setActionSets] = useState([""]);

    const [replyInfo, setReplyInfo] = useState(() => parseReply(searchParams.get("reply")));
    const [to, setTo] = useState("");
    const [subject, setSubject] = useState("");
    const [actionSet, setActionSet] = useState("");
    const [body, setBody] = useState("");

    const loadSubjectQuery = useQuery({
        queryKey: ['Message', replyInfo?.messageId],
        queryFn: () => client.GetInboxRecords({
            MessageIDs: [""+replyInfo?.messageId]
        }),
        enabled: replyInfo !== undefined && replyInfo.subject === undefined
    });

    useEffect(function () {
        if (loadSubjectQuery.data !== undefined) {
            setReplyInfo((prevState) => {
                if (prevState !== undefined) {
                    return {...prevState, subject: "Re: " + loadSubjectQuery.data?.InboxRecords[0].subject};
                }
                return undefined;
            })
        }
    }, [loadSubjectQuery.data]);

    const sendEmailQuery = useMutation({
        mutationFn: () => replyInfo !== undefined ?
            client.SendReply({
                messageId: replyInfo.messageId,
                body: body
            }) :
            client.SendEmail({
                to: to,
                subject: subject,
                body: body,
                actionSet: actionSet
            }),
        onSuccess: (result) => {
            navigate({
                pathname: "/message",
                search: createSearchParams({
                    messageId: btoa(result.message)
                }).toString()
            });
        }
    });

    const loadActionSetQuery = useQuery({
        queryKey: ['ActionSets'],
        queryFn: () => client.ListActionSets()
            .then((result) => ["", ...result.actionSets]),
        initialData: [""]
    });

    return <Page title={replyInfo === undefined ? "Send Email": "Reply"}>
        <Header>{replyInfo === undefined ? "Send Email" : "Send Reply"}</Header>
        <Section>
            <div className="mt-4 grid grid-cols-5">
                {replyInfo === undefined ?
                    <>
                        <span className="col-span-1 h-[32px]">To:</span> <TextInput className="col-span-4" value={to} setValue={setTo}/>
                        <span className="col-span-1 h-[32px]">Subject:</span> <TextInput className="col-span-4" value={subject} setValue={setSubject}/>
                    </>
                    :
                    <>
                        <span className="col-span-1 h-[32px]">To:</span> <span className="col-span-4">{replyInfo.to}</span>
                        <span className="col-span-1 h-[32px]">Subject:</span> <span className="col-span-4">{replyInfo.subject === undefined ? <SingleLinePlaceholder/> : replyInfo.subject}</span>
                    </>
                }
                <span className="col-span-1 h-[32px]">Action Set:</span> <DropDown className="col-span-4" value={actionSet} setValue={setActionSet} choices={loadActionSetQuery.data}/>
                <div className="col-span-5 h-[32px]">Body:</div>
                <TextArea className="col-span-5" value={body} setValue={setBody}/>
                <div className="mt-2 float-right"><MutationButton mutation={sendEmailQuery}>Send</MutationButton></div>
            </div>
        </Section>
    </Page>
}
