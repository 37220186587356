import {
    BackButton,
    Button,
    Checkbox,
    DropDown,
    Header,
    MutationButton,
    Section,
    Subheader,
    TextArea,
    TextInput
} from "../../ui/Base";
import {useEffect, useState} from "react";
import Page from "../../ui/Page";
import {Action, ActionConfig, ActionSet, Field} from "../../client/Client";
import {useClient} from "../../client/AuthClient";
import {useNavigate} from "react-router-dom";
import TabbedPanel from "../../ui/TabbedPanel";
import {ModalDialog} from "../../ui/ModalDialog";
import {useParams} from "react-router";
import {useMutation, useQuery} from "@tanstack/react-query";


function generateAction() {
    return {
        endpoint: ""
    };
}

export function ActionEditor({action, updateAction} : {
    action: Action,
    updateAction: (action: Action) => void
}) {
    if (action === undefined || action === null) {
        action = generateAction();
    }
    return <div className="grid grid-cols-5">
        <span className="col-span-1 h-[32px]">Endpoint:</span> <TextInput className="col-span-4" value={action.endpoint} setValue={(v) => {
        updateAction({...action, endpoint: v})
    }}/>
    </div>
}


export function FieldEditor({fields, updateFields} : {
    fields: Field[],
    updateFields: (fields: Field[]) => void
}) {

    function updateName(index: number, value: string) {
        let newFields = [...fields];
        newFields[index] = {...newFields[index], ...{name: value}}
        updateFields(newFields);
    }

    function updateDescription(index: number, value: string) {
        let newFields = [...fields];
        newFields[index] = {...newFields[index], ...{description: value}}
        updateFields(newFields);
    }

    function updateType(index: number, value: string) {
        let newFields = [...fields];
        // @ts-ignore
        newFields[index] = {...newFields[index], ...{type: value.toUpperCase()}}
        updateFields(newFields);
    }

    function updateRequired(index: number, value: boolean) {
        let newFields = [...fields];
        newFields[index] = {...newFields[index], ...{required: value}}
        updateFields(newFields);
    }


    return <>
        {fields.map(function(element, index) {
            return <div className="border-b border-blue-100 py-4 grid grid-cols-5">
                <span className="col-span-1 h-[32px]">Field Name:</span> <TextInput className="col-span-4" value={element.name} setValue={(v: string) => updateName(index, v)}/>
                <span className="col-span-1 h-[32px]">Type:</span> <DropDown className="col-span-4" value={element.type === null?"":element.type} setValue={(v: string) => updateType(index, v)} choices={["String", "Integer", "Number", "Boolean"]}/>
                <span className="col-span-1 h-[32px]">Required:</span> <span className="col-span-4"><Checkbox value={element.required} setValue={(v: boolean) => updateRequired(index, v)}/></span>
                <div className="col-span-1 h-[32px]">Description:</div>
                <TextArea className="col-span-4" value={element.description} setValue={(v: string) => updateDescription(index, v)}/>
            </div>
        })}
        <Button className="my-4 float-right" onClick={function () {
            // @ts-ignore
            updateFields([...fields, ...[{name: "", description: "", type: "STRING", required:true}]]);
        }}>Add field</Button>
    </>
}


export function ActionRecordEditor({actionRecord, updateActionRecord} : {
    actionRecord: ActionConfig,
    updateActionRecord: (actionRecord: ActionConfig | undefined) => void
}) {

    return <>
        <Subheader>Action: <span className="font-semibold text-gray-600">{actionRecord.name}</span> <Button className="float-right" onClick={() => updateActionRecord(undefined)}>Remove Action</Button></Subheader>
        <Section>
            <div className="mb-4 grid grid-cols-5">
                {/*<span className="col-span-1 h-[32px]">Action Name:</span> <TextInput className="col-span-4" value={actionRecord.name} setValue={(v: string) => {updateActionRecord({...actionRecord, name: v})}}/>*/}
                <div className="col-span-1 h-[32px]">Description:</div>
                <TextArea className="col-span-4" value={actionRecord.description} setValue={(v: string) => {updateActionRecord({...actionRecord, description: v})}}/>
            </div>
        </Section>
        <Subheader>Input Fields:</Subheader>
        <Section>
            <div className="mb-4 grid grid-cols-5">
                <div className="col-span-1"></div>
                <div className="col-span-4">
                    <FieldEditor
                        fields={actionRecord.inputFields}
                        updateFields={(fields: Field[]) => {updateActionRecord({...actionRecord, inputFields: fields})}}
                    />
                </div>
            </div>
        </Section>
        <Subheader>Output Fields:</Subheader>
        <Section>
            <div className="mb-4 grid grid-cols-5">
                <div className="col-span-1"></div>
                <div className="col-span-4">
                    <FieldEditor
                        fields={actionRecord.outputFields}
                        updateFields={(fields: Field[]) => {updateActionRecord({...actionRecord, outputFields: fields})}}
                    />
                </div>
            </div>
        </Section>
        <Subheader>Action:</Subheader>
        <Section>
            <ActionEditor action={actionRecord.action} updateAction={(action: Action) => {updateActionRecord({...actionRecord, action: action})}}/>
        </Section>
    </>;
}


export default function Actions () {
    const navigate = useNavigate();
    const {actionSetName} = useParams();
    const {client} = useClient();
    const [actionData, setActionData] = useState<ActionSet>({
        actions: [],
        defaultEnabled: false,
        defaultAction: generateAction()
    });

    const [tab, setTab] = useState("Default");
    const [newAction, setNewAction] = useState<string|undefined>(undefined);

    const getActionSetQuery = useQuery<ActionSet>({
        queryKey: ['ActionSet', actionSetName],
        queryFn: () => client.GetActionsSet({
            actionSetName : actionSetName === undefined ? "" : actionSetName
        }).then((result) => result.actionSet),
        initialData: {
            actions: [],
            defaultEnabled: false,
            defaultAction: generateAction()
        }
    });

    useEffect(() => {
        setActionData(getActionSetQuery.data);
    }, [getActionSetQuery.data]);

    const updateActionSetQuery = useMutation({
        mutationKey: ['ActionSet', actionSetName],
        mutationFn: () => client.UpdateActionSet({actionSetName: actionSetName === undefined ? "" : actionSetName, actionSet: actionData})
    });

    const updateActionSetQueryThenTest = useMutation({
        mutationKey: ['ActionSet', actionSetName],
        mutationFn: () => client.UpdateActionSet({actionSetName: actionSetName === undefined ? "" : actionSetName, actionSet: actionData}),
        onSuccess: () => navigate("test")
    });

    const deleteActionSetQuery = useMutation({
        mutationKey: ['ActionSets'],
        mutationFn: () => client.DeleteActionSet({actionSetName: actionSetName === undefined ? "" : actionSetName}),
        onSuccess: () => navigate("/actions")
    });

    function addAction(actionName?:string) {
        let newAction: ActionConfig = {
            name: actionName === undefined ? "" : actionName,
            description: "",
            inputFields: [],
            outputFields: [],
            action: generateAction()
        };
        console.log(newAction);
        setActionData(function(state) {
            let s = state as ActionSet;

            return {...state, ...{actions:[...s.actions, newAction]}} as ActionSet;
        });
    }

    function update(index : number, actionRecord: ActionConfig | undefined) {
        setActionData(function(state) {
            let s = state as ActionSet;
            let newActions = [...s.actions];
            if (actionRecord === undefined) {
                if (newActions[index].name === tab) {
                    setTab("Default")
                }
                newActions.splice(index, 1);
            } else {
                newActions[index] = actionRecord;
            }
            return {...state, ...{actions:newActions}} as ActionSet;
        })
    }

    return <Page title="Actions">
        <Header><BackButton link={"/actions/"}/>Edit Actions : {actionSetName}</Header>
        <Section>
            <MutationButton className="m-2 float-right" mutation={updateActionSetQueryThenTest}>Save & Test</MutationButton>
            <MutationButton className="m-2 float-right" mutation={updateActionSetQuery}>Save</MutationButton>
            <MutationButton className="m-2 float-right" mutation={deleteActionSetQuery}>Delete</MutationButton>
        </Section>
        <div className="clear-both"/>
        <TabbedPanel tabs={[...actionData.actions.map(action => action.name), "Default"]} selectedTab={tab} setTab={setTab} onNew={() => setNewAction("")}>
            {tab === "Default" ?
                <>
                    <Subheader>Default action</Subheader>
                    <Section>
                        <div className="mt-4 grid grid-cols-5">
                            <span className="col-span-1">Default Enabled:</span>
                            <div className="col-span-4">
                                <Checkbox
                                    value={actionData.defaultEnabled}
                                    setValue={(value) => setActionData((actionData) => {
                                        let newActionData = {...actionData};
                                        newActionData.defaultEnabled = value;
                                        return newActionData;
                                    })}/></div>
                        </div>
                        <ActionEditor action={actionData.defaultAction} updateAction={(action: Action) => {setActionData({...actionData, defaultAction: action})}}/>
                    </Section>
                </> : <>
                <ActionRecordEditor
                    actionRecord={actionData.actions[actionData.actions.findIndex(action => action.name === tab)]}
                    updateActionRecord={(actionRecord: ActionConfig | undefined) => update(actionData.actions.findIndex(action => action.name === tab), actionRecord)}/>
                </>
            }
        </TabbedPanel>
        <div className="mb-8"/>
        <ModalDialog isOpen={newAction !== undefined} label="New Action" className="max-w-[800px]" requestClose={() => setNewAction(undefined)}>
            <div className="mt-4 grid grid-cols-4 pb-4">
                <span className="col-span-1">Action name:</span>
                <TextInput className="col-span-3" value={newAction === undefined ? "" : newAction} setValue={setNewAction}/>
            </div>
            <Button className="float-right" onClick={() => {
                addAction(newAction);
                setTab(newAction === undefined ? "Default" : newAction);
                setNewAction(undefined)
            }}>New Action</Button>
        </ModalDialog>
    </Page>
}
