function leadingZero (num: number): string {
    var str = num.toString()
    if (str.length < 2) {
        return "0" + str;
    } else {
        return str;
    }
}

export function formatShortDateTime(timestamp: number) {
    let date = new Date(timestamp);
    return (date.getMonth()+1)+"/"+date.getDate()+" "+leadingZero(date.getHours())+":"+leadingZero(date.getMinutes());
}

export function formatFullDate(timestamp: number) {
    let date = new Date(timestamp);
    return date.getFullYear() +"/"+ (date.getMonth()+1)+"/"+date.getDate();
}

export function getEnv(env: string | undefined): string {
    if (env === undefined) {
        throw new Error('Environment Variable Undefined')
    } else {
        return env;
    }
}