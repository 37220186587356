import {DialogPage} from "../../ui/DialogPage";
import {baseTextTailwind, MutationButton, PasswordInput, TextInput} from "../../ui/Base";
import React, {useState} from "react";
import {useClient} from "../../client/AuthClient";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/20/solid";
import {useMutation} from "@tanstack/react-query";


interface PasswordRequirement {
    label: string,
    predicate: (pass: string) => boolean
}

const requirements: PasswordRequirement[] = [
    {label: "8 or more characters.", predicate:(pass) => pass.length >= 8},
    {label: "At least one lower case character.", predicate:(pass) => /[a-z]/.test(pass)},
    {label: "At least one upper case character.", predicate:(pass) => /[A-Z]/.test(pass)},
    {label: "At least one special character (~!@#$%^&*-_+=?).", predicate:(pass) => /[~!@#$%^&*\-_+=?]/.test(pass)},
]

export function PasswordStrengthChecker({password, setPassword}: {password:string, setPassword:(password:string)=>void}) {
    const [passwordFocus, setPasswordFocus] = useState(false);

    return <div className="relative pr-1">
        <input
            type="password"
            className={baseTextTailwind + " w-full"}
            value={password}
            onChange={e => setPassword(e.target.value)}
            onFocus={() => setPasswordFocus(true)}
            onBlur={() => setPasswordFocus(false)}
        />
        {passwordFocus ?
            <div className="absolute z-10 bg-yellow-50 rounded border border-blue-500 p-2 left-full top-0 w-72">
                <span className="text-sm">Password Requirements:{requirements.every((ele) => ele.predicate(password))?
                    <CheckCircleIcon className="inline w-5 h-5 text-green-500"/>:<XCircleIcon className="inline w-5 h-5 text-red-500"/>}</span>
                <ul className="list-disc list-inside">
                    {requirements.map(function (requirement) {
                        return <li className={"text-sm " + (requirement.predicate(password) ? "text-green-600" : "text-red-800")}>{requirement.label}</li>
                    })}
                </ul>
            </div> :
            <></>}
    </div>;
}

export default function Login() {
    const {client
        , authenticate} = useClient();
    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");

    const [signupUsername, setSignupUsername] = useState("");
    const [signupEmail, setSignupEmail] = useState("");
    const [signupPassword, setSignupPassword] = useState("");
    const [signupConfirmPassword, setSignupConfirmPassword] = useState("");

    const loginQuery = useMutation({
            mutationKey: [],
            mutationFn: () => client.Login({
                email: loginEmail,
                password: loginPassword
            }),
            onSuccess: (result) => authenticate(result.authToken)
    });

    const signupQuery = useMutation({
        mutationKey: [],
        mutationFn: () => client.SignUp({
            username: signupUsername,
            email: signupEmail,
            password: signupPassword,
            confirmPassword: signupConfirmPassword
        }),
        onSuccess: (result) => authenticate(result.authToken)
    });

    return <DialogPage title="Login or SignUp">
        <div className="grid grid-cols-1 md:grid-cols-2 h-full my-auto divide-x-2 divide-solid">
            <div className="col-span-1 p-4 px-6">
                <h1 className="text-2xl">Login</h1>
                <div className="grid grid-cols-5 my-8">
                    <span className="col-span-2">Email:</span><TextInput className="col-span-3" value={loginEmail} setValue={setLoginEmail}/>
                    <span className="col-span-2">Password:</span><PasswordInput className="col-span-3" value={loginPassword} setValue={setLoginPassword}/>
                </div>
                <MutationButton className="float-right" mutation={loginQuery}>Login</MutationButton>
            </div>
            <div className="col-span-1 p-4">
                <h1 className="text-2xl">SignUp</h1>
                <div className="grid grid-cols-5 my-8">
                    <span className="col-span-2">Email:</span><TextInput className="col-span-3" value={signupEmail} setValue={setSignupEmail}/>
                    <span className="col-span-2">Username:</span><TextInput className="col-span-3" value={signupUsername} setValue={setSignupUsername}/>
                    <span className="col-span-2">Password:</span>
                    <div className="col-span-3"><PasswordStrengthChecker password={signupPassword} setPassword={setSignupPassword}/></div>
                    <span className="col-span-2">Confirm Password:</span><PasswordInput className="col-span-3" value={signupConfirmPassword} setValue={setSignupConfirmPassword}/>
                </div>
                <MutationButton className="float-right" mutation={signupQuery}>SignUp</MutationButton>
            </div>
        </div>
    </DialogPage>
}