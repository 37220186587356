import Page from "../../ui/Page";
import {
    BackButton,
    Checkbox,
    Header,
    MutationButton,
    Subheader,
    TextArea,
    TextInput,
    TextWithLineBreaks
} from "../../ui/Base";
import {useState} from "react";
import {useClient} from "../../client/AuthClient";
import {useParams} from "react-router";
import {useMutation} from "@tanstack/react-query";


export default function TestActions() {
    const {actionSetName} = useParams();

    const {client} = useClient();

    const [from, setFrom] = useState("");
    const [subject, setSubject] = useState("");
    const [email, setEmail] = useState("");
    const [perform, setPerform] = useState(false);

    const testAction = useMutation({
        mutationKey: ['testAction'],
        mutationFn: () => client.TestActionSet({
            testFrom: from,
            testSubject: subject,
            testEmail: email,
            actionSetName: actionSetName === undefined ? "" : actionSetName,
            performAction: perform
        })
    });

    function result() {
        if (testAction.status === "success" && testAction.data !== undefined) {
            return <div className="m-6 grid grid-cols-5">
                <span className="col-span-1 h-[32px]">Chosen Action:</span> <span className="col-span-4">{testAction.data.action}</span>
                {testAction.data.extractedInput == null ? <></> : <>
                    <span className="col-span-1">Extracted Data:</span>
                    <pre className="col-span-4 bg-blue-50 rounded mb-2 p-2 px-4">
                        {JSON.stringify(testAction.data.extractedInput, null, 2)}
                    </pre>
                </>}
                {testAction.data.extractedOutput == null ? <></> : <>
                    <span className="col-span-1">Action Result:</span>
                    <pre className="col-span-4 bg-blue-50 rounded mb-2 p-2 px-4">
                        {JSON.stringify(testAction.data.extractedOutput, null, 2)}
                    </pre>
                </>}
                {testAction.data.sampleReply == null ? <></> : <>
                    <span className="col-span-1">Sample Reply:</span>
                    <TextWithLineBreaks className="col-span-4 bg-blue-50 rounded mb-2 p-2 px-4"
                                        text={testAction.data.sampleReply}/>
                </>}
                {testAction.data.actionError == null ? <></> : <>
                    <span className="col-span-1">Action Error:</span>
                    <TextWithLineBreaks className="col-span-4 bg-red-200 rounded mb-2 p-2 px-4"
                                        text={testAction.data.actionError}/>
                </>}
            </div>
        } else {
            return <></>;
        }
    }

    return <Page title={"Test Actions: " + actionSetName}>
        <Header><BackButton link={"/actions/" + actionSetName}/>Test Actions: {actionSetName}</Header>
        <Subheader>Test data:</Subheader>
        <div className="m-6 grid grid-cols-5">
            <span className="col-span-1 h-[32px]">From:</span> <TextInput className="col-span-4" value={from} setValue={setFrom}/>
            <span className="col-span-1 h-[32px]">Subject:</span> <TextInput className="col-span-4" value={subject} setValue={setSubject}/>
            <div className="col-span-1 h-[32px]">Email:</div>
            <TextArea className="col-span-4" value={email} setValue={setEmail}/>
            <span className="col-span-1 h-[32px]">Perform Action:</span> <span className="col-span-4"><Checkbox value={perform} setValue={setPerform}/></span>
            <span className="col-span-5"><MutationButton className="float-right" mutation={testAction}>Test</MutationButton></span>
        </div>
        <Subheader>Results:</Subheader>
        {result()}
    </Page>
}
