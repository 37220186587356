/* GENERATED FILE */

export interface ActionConfig {
  name: string,
  description: string,
  inputFields: Field[],
  outputFields: Field[],
  action: Action,
}

export interface Field {
  name: string,
  description: string,
  type: string,
  required: boolean,
}

export interface Action {
  endpoint: string,
}

export interface InboxRecord {
  subject: string,
  messageNum: number,
  messageId: string,
}

export interface InboxRow {
  emailData: EmailData,
  inboxRecord: InboxRecord,
}

export interface ReplyInfo {
  messageId: string,
  actionName: string,
  actionSet: string,
  replyStatus: string,
  extractedData: any,
  outputData: any,
  error: string,
}

export interface EmailData {
  to: ParsedAddress[],
  from: ParsedAddress,
  timestamp: number,
  messageId: string,
  repliedFromId: string,
  replyInfo: ReplyInfo,
}

export interface ActionSet {
  actions: ActionConfig[],
  defaultEnabled: boolean,
  defaultAction: Action,
}

export interface ParsedAddress {
  email: string,
  displayName: string,
}


export function Client()
{
  let clientData: {url: string, headers: { [key: string]: string }, errorHandler: (error:any)=>void, responseErrorHandler: (response: Response)=>any}  = {
    url : "http://localhost:8888/",
    headers : {},
    errorHandler: (error:any)=>{throw error},
    responseErrorHandler: (response: Response)=>Promise.reject("Invalid response code: " + response.status + "/" + response.statusText)
  }
  let handleResponse = (response: Response) => response.ok? response.json() : clientData.responseErrorHandler(response);
  return {
    setUrl: function(newUrl: string) {clientData.url = newUrl},
    addHeader: function(headerName: string, headerValue: string) {clientData.headers[headerName] = headerValue},
    hasHeader: function(headerName: string) {return headerName in clientData.headers},
    clearHeader: function(headerName: string) {delete clientData.headers[headerName]},
    setErrorHandler: function(errorHandler: (error:any)=>void) {clientData.errorHandler = errorHandler},
    setResponseErrorHandler: function(errorHandler: (error:any)=>void) {clientData.responseErrorHandler = errorHandler},

    CreateActionSet: function(data: {
      actionSetName: string,
    }): Promise<{
      actionSet: ActionSet,
    }> {
      return fetch(clientData.url+"CreateActionSet", {
        method: "POST",
        headers: clientData.headers,
        body: JSON.stringify(data),
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },

    GetMailboxSettings: function(): Promise<{
      imapHost: string,
      smtpHost: string,
      username: string,
      password: string,
      active: boolean,
      agentName: string,
      actionSet: string,
    }> {
      return fetch(clientData.url+"GetMailboxSettings", {
        method: "POST",
        headers: clientData.headers,
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },

    SignUp: function(data: {
      email: string,
      username: string,
      password: string,
      confirmPassword: string,
    }): Promise<{
      authToken: string,
    }> {
      return fetch(clientData.url+"SignUp", {
        method: "POST",
        headers: clientData.headers,
        body: JSON.stringify(data),
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },

    GetEmailRecords: function(data: {
      before: string,
    }): Promise<{
      emailData: EmailData[],
    }> {
      return fetch(clientData.url+"GetEmailRecords", {
        method: "POST",
        headers: clientData.headers,
        body: JSON.stringify(data),
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },

    DeleteActionSet: function(data: {
      actionSetName: string,
    }): Promise<{}> {
      return fetch(clientData.url+"DeleteActionSet", {
        method: "POST",
        headers: clientData.headers,
        body: JSON.stringify(data),
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },

    ScanInbox: function(data: {
      lastScanned: string,
    }): Promise<{
      inboxRows: InboxRow[],
    }> {
      return fetch(clientData.url+"ScanInbox", {
        method: "POST",
        headers: clientData.headers,
        body: JSON.stringify(data),
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },

    GetSubscriptionInfo: function(): Promise<{
      subscriptionId: string,
      createdAt: number,
      next: number,
      cancelledAt: number,
    }> {
      return fetch(clientData.url+"GetSubscriptionInfo", {
        method: "POST",
        headers: clientData.headers,
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },

    UpdatePassword: function(data: {
      password: string,
      confirmPassword: string,
    }): Promise<{}> {
      return fetch(clientData.url+"UpdatePassword", {
        method: "POST",
        headers: clientData.headers,
        body: JSON.stringify(data),
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },

    RegisterEmail: function(data: {
      email: string,
    }): Promise<{}> {
      return fetch(clientData.url+"RegisterEmail", {
        method: "POST",
        headers: clientData.headers,
        body: JSON.stringify(data),
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },

    SendEmail: function(data: {
      to: string,
      subject: string,
      actionSet: string,
      body: string,
    }): Promise<{
      message: string,
    }> {
      return fetch(clientData.url+"SendEmail", {
        method: "POST",
        headers: clientData.headers,
        body: JSON.stringify(data),
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },

    GetInboxRecords: function(data: {
      MessageIDs: string[],
    }): Promise<{
      InboxRecords: InboxRecord[],
    }> {
      return fetch(clientData.url+"GetInboxRecords", {
        method: "POST",
        headers: clientData.headers,
        body: JSON.stringify(data),
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },

    GetApiKey: function(data: {
      password: string,
    }): Promise<{
      apiKey: string,
    }> {
      return fetch(clientData.url+"GetApiKey", {
        method: "POST",
        headers: clientData.headers,
        body: JSON.stringify(data),
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },

    VerifyEmail: function(data: {
      code: string,
    }): Promise<{
      error: string,
    }> {
      return fetch(clientData.url+"VerifyEmail", {
        method: "POST",
        headers: clientData.headers,
        body: JSON.stringify(data),
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },

    NewSubscription: function(): Promise<{
      subscriptionId: string,
      clientSecret: string,
    }> {
      return fetch(clientData.url+"NewSubscription", {
        method: "POST",
        headers: clientData.headers,
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },

    UpdateActionSet: function(data: {
      actionSetName: string,
      actionSet: ActionSet,
    }): Promise<{}> {
      return fetch(clientData.url+"UpdateActionSet", {
        method: "POST",
        headers: clientData.headers,
        body: JSON.stringify(data),
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },

    GetAccountInfo: function(): Promise<{
      isVerified: boolean,
    }> {
      return fetch(clientData.url+"GetAccountInfo", {
        method: "POST",
        headers: clientData.headers,
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },

    TestActionSet: function(data: {
      actionSetName: string,
      testFrom: string,
      testSubject: string,
      testEmail: string,
      performAction: boolean,
    }): Promise<{
      action: string,
      extractedInput: any,
      actionError: string,
      extractedOutput: any,
      sampleReply: string,
    }> {
      return fetch(clientData.url+"TestActionSet", {
        method: "POST",
        headers: clientData.headers,
        body: JSON.stringify(data),
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },

    TriggerScan: function(data: {
      messageId: string,
    }): Promise<{
      replyInfo: ReplyInfo,
    }> {
      return fetch(clientData.url+"TriggerScan", {
        method: "POST",
        headers: clientData.headers,
        body: JSON.stringify(data),
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },

    SendReply: function(data: {
      messageId: string,
      body: string,
    }): Promise<{
      message: string,
    }> {
      return fetch(clientData.url+"SendReply", {
        method: "POST",
        headers: clientData.headers,
        body: JSON.stringify(data),
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },

    Login: function(data: {
      email: string,
      password: string,
    }): Promise<{
      authToken: string,
    }> {
      return fetch(clientData.url+"Login", {
        method: "POST",
        headers: clientData.headers,
        body: JSON.stringify(data),
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },

    CancelSubscription: function(): Promise<{}> {
      return fetch(clientData.url+"CancelSubscription", {
        method: "POST",
        headers: clientData.headers,
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },

    ActivateMailbox: function(data: {
      activate: boolean,
    }): Promise<{}> {
      return fetch(clientData.url+"ActivateMailbox", {
        method: "POST",
        headers: clientData.headers,
        body: JSON.stringify(data),
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },

    UpdateMailboxSettings: function(data: {
      imapHost: string,
      smtpHost: string,
      username: string,
      password: string,
      agentName: string,
      actionSet: string,
    }): Promise<{}> {
      return fetch(clientData.url+"UpdateMailboxSettings", {
        method: "POST",
        headers: clientData.headers,
        body: JSON.stringify(data),
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },

    ListActionSets: function(): Promise<{
      actionSets: string[],
    }> {
      return fetch(clientData.url+"ListActionSets", {
        method: "POST",
        headers: clientData.headers,
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },

    ResendVerification: function(): Promise<{}> {
      return fetch(clientData.url+"ResendVerification", {
        method: "POST",
        headers: clientData.headers,
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },

    GetActionsSet: function(data: {
      actionSetName: string,
    }): Promise<{
      actionSet: ActionSet,
    }> {
      return fetch(clientData.url+"GetActionsSet", {
        method: "POST",
        headers: clientData.headers,
        body: JSON.stringify(data),
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },

    GetMessage: function(data: {
      messageId: string,
    }): Promise<{
      emailData: EmailData,
      inboxRecord: InboxRecord,
      body: string,
    }> {
      return fetch(clientData.url+"GetMessage", {
        method: "POST",
        headers: clientData.headers,
        body: JSON.stringify(data),
      })
      .then(handleResponse)
      .catch(clientData.errorHandler);
    },
  }
}
