import {ReactNode} from "react";
import Modal from "react-modal";
import {Header} from "./Base";
import {XCircleIcon} from "@heroicons/react/20/solid";

export function ModalDialog({isOpen, requestClose, label, className, children} : {isOpen:boolean, requestClose:() => void, label:string, className?:string, children:ReactNode} ) {
    return <Modal
        isOpen={isOpen}
        onRequestClose={requestClose}
        contentLabel={label}
        className={"bg-white border-blue-800 border-2 rounded-lg mx-auto mt-16 " + (className === undefined ? "" : className)}
        >
        <Header className="rounded-t-lg">{label}<span className="float-right text-gray-500 hover:text-gray-900 hover:cursor-pointer m-1" onClick={requestClose}><XCircleIcon aria-hidden="true" className="h-5 w-5"/></span></Header>
        <div className="p-4">
            {children}
            <div className="clear-both"/>
        </div>
    </Modal>
}
