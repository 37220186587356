import React, {createContext, ReactNode, useContext, useState} from "react";
import {Client} from "./Client";
import {ModalDialog} from "../ui/ModalDialog";

var jwt_header_name = "X-jwt";
var jwt_storage_name = "X-jwt";

export const ClientContext = createContext<{token:string|null, setToken:(newToken:string|null) => void}>({token:null, setToken:(token:string|null) => undefined});
export const ErrorContext = createContext<(newError:string|null) => void>((newError:string|null) => undefined);

export function ClientController({children}: {children: ReactNode}) {
    let [token, setToken] = useState(() => localStorage.getItem(jwt_storage_name));
    let [error, setError] = useState<string|null>(null);

    return <ClientContext.Provider value={{token:token, setToken:setToken}}>
        <ErrorContext.Provider value={setError}>
            {children}
            <ModalDialog isOpen={error != null} requestClose={() => setError(null)} label="Error" className="w-[500px]">
                <div className="p-10">
                    <span className="text-red-800">{error}</span>
                </div>
            </ModalDialog>
        </ErrorContext.Provider>
    </ClientContext.Provider>
}

export function useClient() {
    const {token, setToken} = useContext(ClientContext);
    const setError = useContext(ErrorContext);

    var username = undefined;
    var email = undefined;

    let client
        = Client();

    if (process.env.REACT_APP_API !== undefined) {
        client.setUrl(process.env.REACT_APP_API);
    }
    client.setErrorHandler((error) => {
        console.log(error);
        throw error;
    });
    client.setResponseErrorHandler((error : Response) => {
        if (error.status === 400) {
            error.json()
                .then(data => data.message)
                .then(message => setError(message));
        }
        return Promise.reject("Bad Request");
    });

    if (token !== null) {
        var tokenData = JSON.parse(token);
        if ("token" in tokenData) {
            client.addHeader(jwt_header_name, tokenData.token);
        }
        if ("username" in tokenData) {
            username = tokenData.username;
        }
        if ("email" in tokenData) {
            email = tokenData.email;
        }
    }

    return {
        client: client,
        username: username,
        email: email,
        authenticate: function (authToken: string) {
            localStorage.setItem(jwt_storage_name, authToken);
            setToken(authToken);
        },
        isAuthenticated: token !== null,
        clearAuthentication() {
            localStorage.removeItem(jwt_storage_name);
            setToken(null);
        }
    }
}
