import "./Pulse.css"

export function SingleLinePlaceholder() {
    return <div className="h-[1.25rem] bg-gray-300 rounded-full w-full animation-pulse"/>;
}

export function SingleLinePlaceholderBlank() {
    return <div className="h-[1.25rem] w-full"/>;
}

let sampleLines = [
    [10, 20],
    [0],
    [30, 20, 30, 10],
    [20, 30, 10, 15],
    [10, 5, 25, 20, 30],
    [5, 15, 20],
    [0],
    [25]
]

export function EmailPlaceholder() {
    return <div className="space-y-2.5 w-full">
        {sampleLines.map((lines, index) =>
            <div key={index} className="flex w-full space-x-2.5">
                {lines.map((line, index) =>
                    <div key={index} className="h-[1.25rem] bg-gray-200 rounded-full animation-pulse" style={{width:line+"%"}}/>
                    )}
            </div>
        )}
    </div>
}
