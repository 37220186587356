import {
    Button,
    CopyBox,
    DropDown,
    Header,
    MutationButton,
    PasswordInput,
    Section,
    Subheader,
    TextInput
} from "../ui/Base";
import {useEffect, useState} from "react";
import Page from "../ui/Page";
import {useClient} from "../client/AuthClient";
import Stripe from "../ui/billing/Stripe";
import {PasswordStrengthChecker} from "./account/Login";
import {ModalDialog} from "../ui/ModalDialog";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/20/solid";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";


function InboxSettings() {
    const queryClient = useQueryClient();
    const {client
    } = useClient();

    const [imapHost, setImapHost] = useState("");
    const [smtpHost, setSmtpHost] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [actionSet, setActionSet] = useState("");
    const [agentName, setAgentName] = useState("");
    const [active, setActive] = useState(false);

    const inboxQuery = useQuery({
        queryKey: ['InboxSettings'],
        queryFn: () => client.GetMailboxSettings()
    });

    useEffect(() => {
        if (inboxQuery.data !== undefined) {
            setImapHost(inboxQuery.data.imapHost);
            setSmtpHost(inboxQuery.data.smtpHost);
            setUsername(inboxQuery.data.username);
            setPassword(inboxQuery.data.password);
            setAgentName(inboxQuery.data.agentName);
            setActionSet(inboxQuery.data.actionSet)
            setActive(inboxQuery.data.active);
        }
    }, [inboxQuery.data]);

    const updateMailboxQuery = useMutation({
        mutationKey: ['InboxSettings'],
        mutationFn: () => client.UpdateMailboxSettings({
            imapHost: imapHost,
            smtpHost: smtpHost,
            username: username,
            password: password,
            actionSet: actionSet,
            agentName: agentName
        }),
        onSuccess: () => {
            return queryClient.invalidateQueries({
                queryKey: ['InboxSettings']
            });
        }
    });

    const actionsQuery = useQuery({
        queryKey: ['ActionSets'],
        queryFn: () => client.ListActionSets().then((result) => ["", ...result.actionSets]),
        initialData: [""],
    });

    const activateMailboxQuery = useMutation({
        mutationKey: ['InboxSettings'],
        mutationFn: () => client.ActivateMailbox({
            activate: !active,
        }),
        onSuccess: () => {
            return queryClient.invalidateQueries({
                queryKey: ['InboxSettings']
            });
        }
    });

    return <>
        <Subheader>Mailbox</Subheader>
        <Section>
            <div className="grid grid-cols-5">
                <span className="col-span-1 h-[32px]">imapHost:</span> <TextInput className="col-span-4" value={imapHost} setValue={setImapHost}/>
                <span className="col-span-1 h-[32px]">smtpHost:</span> <TextInput className="col-span-4" value={smtpHost} setValue={setSmtpHost}/>
                <span className="col-span-1 h-[32px]">username:</span> <TextInput className="col-span-4" value={username} setValue={setUsername}/>
                <span className="col-span-1 h-[32px]">password:</span> <PasswordInput className="col-span-4" value={password} setValue={setPassword}/>
                <span className="col-span-5 h-[32px]"/>
                <span className="col-span-1 h-[32px]">action set:</span> <span className="col-span-4"><DropDown value={actionSet} setValue={setActionSet} choices={actionsQuery.data}/></span>
                <span className="col-span-1 h-[32px]">agent name:</span> <TextInput className="col-span-4" value={agentName} setValue={setAgentName}/>
                <div className="col-span-5 pt-2"> <MutationButton className="float-right ml-4" disabled={active} mutation={updateMailboxQuery}>Save</MutationButton> <MutationButton className="float-right" mutation={activateMailboxQuery}>{active?"Deactivate":"Activate"}</MutationButton> </div>
            </div>
        </Section>
    </>
}

function ApiKeySettings() {
    const {client
    } = useClient();
    const [openModal, setOpenModal] = useState(false);
    const [apiKeyPassword, setApiKeyPassword] = useState("");

    const getApiKeyQuery = useMutation({
        mutationKey: ["apiKey"],
        mutationFn: () => client.GetApiKey({password: apiKeyPassword}).then(result => result.apiKey)
    });

    return <>
        <Subheader>Api Key</Subheader>
        <Section>
            <div className="grid grid-cols-5">
                <div className="col-span-1"/>
                <div className="col-span-4">
                    Use your API key to access to secure webhooks, to access our API or connect with Zapier.
                    <Button className="float-right" onClick={() => setOpenModal(true)}>Get your API key</Button>
                </div>
            </div>
            <ModalDialog isOpen={openModal} requestClose={() => {setOpenModal(false); getApiKeyQuery.reset()}} className="max-w-[500px]" label="Api Key">
                {getApiKeyQuery.data === undefined ?
                    <div>
                        Confirm your password to access your api key:
                        <PasswordInput value={apiKeyPassword} setValue={setApiKeyPassword}/>
                        <MutationButton className="float-right" mutation={getApiKeyQuery}>Get Key</MutationButton>
                    </div>
                    :
                    <div>
                        <CopyBox text={""+getApiKeyQuery.data}/>
                    </div>
                }
            </ModalDialog>
        </Section>
    </>;
}

function BillingSettings() {
    return <>
        <Subheader>Billing</Subheader>
        <Section>
            <Stripe/>
        </Section>
    </>
}

function AccountSettings() {
    const {client
        , clearAuthentication, email, username
    } = useClient();

    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const accountInfoQuery = useQuery({
            queryKey: ['AccountInfo'],
            queryFn: () => client.GetAccountInfo()
    });

    const updatePasswordQuery = useMutation({
        mutationFn: () => client.UpdatePassword({
            password: password,
            confirmPassword: passwordConfirm
        })
    });

    const resendVerification = useMutation({
        mutationFn: () => client.ResendVerification()
    });

    function emailVerified() {
        if (accountInfoQuery.isLoading) {
            return <></>
        } else if (!accountInfoQuery.data?.isVerified) {
            return <>
                <MutationButton className="float-right" mutation={resendVerification}>Resend</MutationButton>
                <span className="float-right mr-2 font-semibold text-red-700"><XCircleIcon aria-hidden="true" className="-mt-1 h-5 w-5 inline"/>Not Verified</span>
            </>
        } else {
            return <span className="float-right font-semibold text-green-700"><CheckCircleIcon aria-hidden="true" className="-mt-1 h-5 w-5 inline"/>Verified</span>
        }
    }

    return <>
        <Subheader>Account</Subheader>
        <Section>
            <div className="grid grid-cols-5">
                <span className="col-span-1 h-[32px]">username:</span> <span className="col-span-4">{username}</span>
                <span className="col-span-1 h-[32px]">email:</span> <span className="col-span-2 lg:col-span-3">{email}</span><span className="col-span-2 lg:col-span-1">{emailVerified()}</span>
                <span className="col-span-5 h-[32px]"><Button className="float-right" onClick={() => clearAuthentication()}>Logout</Button></span>
            </div>
        </Section>

        <Subheader>Update Password</Subheader>
        <Section>
            <div className="grid grid-cols-5">
                <span className="col-span-1 h-[32px]">password:</span> <span className="col-span-4"> <PasswordStrengthChecker password={password} setPassword={setPassword}/> </span>
                <span className="col-span-1 h-[32px]">confirm:</span> <PasswordInput className="col-span-4" value={passwordConfirm} setValue={setPasswordConfirm}/>
                <div className="col-span-5 pt-2"><MutationButton className="float-right" mutation={updatePasswordQuery}>Save</MutationButton></div>
            </div>
        </Section>
    </>
}

export default function Settings() {
    return <Page title={"Settings"}>
        <Header>Settings</Header>
        <div>
            <InboxSettings/>
            <ApiKeySettings/>
            <AccountSettings/>
            <BillingSettings/>
        </div>
    </Page>;
}
