import Page from "../ui/Page";
import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";
import {useState} from "react";
import {EmailData, InboxRecord, ReplyInfo} from "../client/Client";
import {Button, Header, Section, TextWithLineBreaks} from "../ui/Base";
import {useClient} from "../client/AuthClient";
import "../ui/Collapse.css";
import {formatShortDateTime} from "../ui/Utils";
import {EmailPlaceholder, SingleLinePlaceholder} from "../ui/Placeholders";
import Address from "../ui/Address";
import {useQuery} from "@tanstack/react-query";

interface MessageRecord {
    emailData: EmailData,
    inboxRecord: InboxRecord,
    body: string
}

export default function Message () {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const {client} = useClient();

    const [replyWindow, setReplyWindow] = useState<ReplyInfo | undefined>();

    let messageId = searchParams.get("messageId");

    const messageDataQuery = useQuery({
        queryKey: ['Message', messageId],
        queryFn: () => messageId === null ?
            Promise.reject("Invalid message Id.") :
            client.GetMessage({
                messageId: atob(messageId)
            }),
        staleTime: Infinity
    });

    function goToMessage(messageId: string) {
        setReplyWindow(undefined);
        navigate({
            pathname: "/message",
            search: createSearchParams({
                messageId: btoa(messageId)
            }).toString()
        });
    }

    function getInReplyButton(replyId: string | null) {
        if (replyId === null) {
            return <></>;
        }
        return <Button onClick={() => goToMessage(replyId)}>In Reply</Button>
    }

    function getRepliedButton(replyInfo : ReplyInfo) {
        if (replyInfo === null) {
            return <></>
        } else if (replyInfo.replyStatus === "UNSCANNED") {
            return <div className="inline-block bg-gray-200 text-gray-600 rounded-md px-3 py-0.5 font-semibold">Unscanned</div>
        } else if (replyInfo.replyStatus === "NO_ACTION") {
            return <div className="inline-block bg-gray-200 text-gray-600 rounded-md px-3 py-0.5 font-semibold">No Action</div>
        } else if (replyInfo.replyStatus === "ACTION_AWAIT") {
            return <Button onClick={() => replyWindow === undefined ? setReplyWindow(replyInfo) : setReplyWindow(undefined)}>Async</Button>
        } else if (replyInfo.replyStatus === "REPLIED") {
            return <Button onClick={() => replyWindow === undefined ? setReplyWindow(replyInfo) : setReplyWindow(undefined)}>Replied</Button>
        } else if (replyInfo.replyStatus === "FOLLOWUP") {
            return <Button onClick={() => replyWindow === undefined ? setReplyWindow(replyInfo) : setReplyWindow(undefined)}>Followed Up</Button>
        } else if (replyInfo.replyStatus === "ERROR") {
            return <Button onClick={() => replyWindow === undefined ? setReplyWindow(replyInfo) : setReplyWindow(undefined)}>Error</Button>
        }
        return <></>
    }

    function getRepliedInfo(messageData : MessageRecord | undefined) {
        if (messageData === undefined || messageData.emailData.replyInfo === undefined || messageData.emailData.replyInfo === null) {
            return <Section><></></Section>;
        }
        let replyInfo: ReplyInfo = messageData.emailData.replyInfo;
        if (replyInfo.replyStatus === "REPLIED") {
            return <Section>
                <div className={"col-span-5 collapsable" + (replyWindow === undefined ? "" : " expanded")}>
                    <div className="bg-blue-50 h-full overflow-hidden rounded-xl">
                        <div className="p-4">
                            <div className="grid grid-cols-2">
                                <div>
                                    <span className="font-bold">Action:</span> {replyInfo.actionName}
                                </div>
                                <div className="text-right">
                                    <Button onClick={() => goToMessage(replyInfo.messageId)}>
                                        View Reply
                                    </Button>
                                </div>
                                <div>
                                    <span className="font-bold">Input:</span>
                                    <pre>
                                    {JSON.stringify(replyInfo.extractedData, null, 2)}
                                </pre>
                                </div>
                                <div>
                                    <span className="font-bold">Output:</span>
                                    <pre>
                                        {JSON.stringify(replyInfo.outputData, null, 2)}
                                    </pre>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
        } else if (replyInfo.replyStatus === "ACTION_AWAIT") {
            return <Section>
                <div className={"col-span-5 collapsable" + (replyWindow === undefined ? "" : " expanded")}>
                    <div className="bg-blue-50 h-full overflow-hidden rounded-xl">
                        <div className="p-4">
                            <div className="grid grid-cols-2">
                                <div>
                                    <span className="font-bold">Action is awaiting completion.</span> <br/>
                                    <span className="font-bold">Action:</span> {replyInfo.actionName}
                                </div>
                                <div>
                                    <span className="font-bold">Input:</span>
                                    <pre>
                                        {JSON.stringify(replyInfo.extractedData, null, 2)}
                                    </pre>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
        } else if (replyInfo.replyStatus === "FOLLOWUP") {
            return <Section>
                <div className={"col-span-5 collapsable" + (replyWindow === undefined ? "" : " expanded")}>
                    <div className="bg-blue-50 h-full overflow-hidden rounded-xl">
                        <div className="p-4">
                            <div className="grid grid-cols-2">
                                <div>
                                    <span className="font-bold">Action:</span> {replyInfo.actionName}
                                </div>
                                <div className="text-right">
                                    <Button onClick={() => goToMessage(replyInfo.messageId)}>
                                        View Followup
                                    </Button>
                                </div>
                                <div>
                                    <span className="font-bold">Input:</span>
                                    <pre>
                                        {JSON.stringify(replyInfo.extractedData, null, 2)}
                                    </pre>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
        } else if (replyInfo.replyStatus === "ERROR") {
            return <Section>
                <div className={"col-span-5 collapsable" + (replyWindow === undefined ? "" : " expanded")}>
                    <div className="bg-blue-50 h-full overflow-hidden rounded-xl">
                        <div className="p-4">
                            <div className="grid grid-cols-2">
                                <div>
                                    <span className="font-bold">Action:</span> {replyInfo.actionName}
                                    <br/>
                                    <span className="font-bold">Input:</span>
                                    <pre>
                                        {JSON.stringify(replyInfo.extractedData, null, 2)}
                                    </pre>
                                </div>
                                <div>
                                    <span className="font-bold">Error:</span>
                                    <pre className="overflow-auto">
                                        {replyInfo.error}
                                    </pre>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
        }
    }

    function message() {
        if (messageDataQuery.data === null) {
            return <></>
        }

        // @ts-ignore
        return <div>
            <Header>Message</Header>
            <Section>
                <div className="grid grid-cols-7 mx-4">
                    {messageDataQuery.data?.emailData.replyInfo === null || !messageDataQuery.data?.emailData.replyInfo.replyStatus.startsWith("IN_")?
                        <>
                            <span className="col-span-1 h-[32px]">From:</span>
                            <span className="col-span-4">{messageDataQuery.data === undefined ? <SingleLinePlaceholder/> : <Address address={messageDataQuery.data.emailData.from}/>}</span>
                        </>: <>
                            <span className="col-span-1 h-[32px]">To:</span>
                            <span className="col-span-4">{messageDataQuery.data === undefined ? <SingleLinePlaceholder/> : messageDataQuery.data.emailData.to.map(function (ele) {return <Address address={ele}/>})}</span>
                        </>
                    }
                    <span className="col-span-2 text-right">
                        {messageDataQuery.data !== undefined ? <>
                            {getRepliedButton(messageDataQuery.data?.emailData.replyInfo)}
                            {getInReplyButton(messageDataQuery.data?.emailData.repliedFromId)}
                        </> : <></>}
                    </span>
                    <span className="col-span-1 h-[32px]">Subject:</span>
                    <span className="col-span-5">{messageDataQuery.data === undefined ? <SingleLinePlaceholder/> : messageDataQuery.data?.inboxRecord.subject}</span>
                    <span className="col-span-1 h-[32px] text-right ml-4">{messageDataQuery.data === undefined ? <SingleLinePlaceholder/> : formatShortDateTime(messageDataQuery.data.emailData.timestamp)}</span>
                </div>
            </Section>
            {getRepliedInfo(messageDataQuery.data)}
            <Section>
                <div className="border rounded-xl p-4 overflow-x-auto">
                    {messageDataQuery.data === undefined ?
                        <EmailPlaceholder/>
                        :
                        <TextWithLineBreaks text={messageDataQuery.data.body}/>
                    }
                </div>
            </Section>
        </div>
    }

    return <Page title="Open Email">
        {message()}
    </Page>
}