import React, {useEffect} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {ClientController, useClient} from "./app/client/AuthClient";
import Home from "./app/pages/Home";
import NotFound from "./app/pages/NotFound";
import Inbox from "./app/pages/Inbox";
import Send from "./app/pages/Send";
import Settings from "./app/pages/Settings";
import Actions from "./app/pages/actions/Actions";
import Login from "./app/pages/account/Login";
import VerifyEmail from "./app/pages/account/VerifyEmail";
import Message from "./app/pages/Message";
import TestActions from "./app/pages/actions/TestActions";
import ActionList from "./app/pages/actions/ActionList";
import {Crisp} from "crisp-sdk-web";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

function AppRouter() {
    const {isAuthenticated, email, username} = useClient();

    useEffect(() => {
        Crisp.configure("ef6bd5a5-0bb0-42da-8d3b-12f23b7fa1c6");
    });

    useEffect(() => {
        if (email !== undefined && email !== null) {
            Crisp.user.setEmail(email);
        }
        if (username !== undefined && username !== null) {
            Crisp.user.setNickname(username);
        }
    }, [email, username])

    return <BrowserRouter>
        {isAuthenticated?
            <Routes>
                <Route path="/" Component={Home}/>
                <Route path="/inbox" Component={Inbox}/>
                <Route path="/send" Component={Send}/>
                <Route path="/actions" Component={ActionList}/>
                <Route path="/actions/:actionSetName" Component={Actions}/>
                <Route path="/actions/:actionSetName/test" Component={TestActions}/>
                <Route path="/settings" Component={Settings}/>
                <Route path="/message" Component={Message}/>

                <Route path="/login" element={<Navigate to="/"/>}/>
                <Route path="/verify" Component={VerifyEmail}/>

                <Route path="/*" Component={NotFound}/>
            </Routes>:
            <Routes>
                <Route path="/login" Component={Login}/>
                <Route path="/verify" Component={VerifyEmail}/>

                <Route path="/*" element={<Navigate to="/login"/>}/>
            </Routes>
        }
    </BrowserRouter>
}

function App() {
    const queryClient = new QueryClient();

    return (
        <div className="h-full">
            <QueryClientProvider client={queryClient}>
                <ClientController>
                    <AppRouter/>
                </ClientController>
            </QueryClientProvider>
        </div>
    );
}

export default App;
