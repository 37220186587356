import {Link} from "react-router-dom";
import {ReactNode} from "react";
import {Helmet} from "react-helmet";


export function BasePage({children, title} : {children: ReactNode, title:string}) {
    let tabClasses = "inline mx-4 p-2 hover:underline";

    return <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <div className="flex flex-col min-h-[100%] bg-[#F0F0F0]">
            <div className="w-[100%] py-6 bg-white drop-shadow-xl">
                <div className="flex max-w-[1280px] mx-auto">
                    <div className="inline-block flex-grow">
                        <div className="inline p-2 mr-8 font-extrabold text-xl">
                            <Link to={"/"}>
                                <img alt="replybot logo" className="inline h-8 ml-1 -mt-1" src={process.env.PUBLIC_URL + '/logo192.png'} />
                                <span className="text-[#004080]">Reply</span><span className="text-[#D32F2F]">Bot</span><span className="text-blue-700">.io</span>
                            </Link>
                        </div>
                    </div>
                    <div className="inline-block max-w-[1024px]">
                        <Link to={"/inbox"}><div className={tabClasses}>Inbox</div></Link>
                        <Link to={"/send"}><div className={tabClasses}>Send</div></Link>
                        <Link to={"/actions"}><div className={tabClasses}>Actions</div></Link>
                        <Link to={"/settings"}><div className={tabClasses}>Settings</div></Link>
                    </div>
                </div>
            </div>
            <div className="flex-grow">
                {children}
            </div>
            <div className="text-center p-10 text-gray-500">
                contact@replybot.io<span className="mx-4">◇</span>© 2024 Jotting, Inc
            </div>
        </div>
    </>
}

export default function Page({children, title} : {children: ReactNode, title:string}) {
    return <BasePage title={title}>
        <div className="max-w-[1024px] h-full bg-white mx-auto block drop-shadow-lg border-t-2 pb-8 rounded-b">
            {children}
            <div className="clear-both"/>
        </div>
    </BasePage>
}
