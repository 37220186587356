import React, {ReactNode, useState} from "react";
import {Listbox, ListboxButton, ListboxOption, ListboxOptions, Switch} from "@headlessui/react";
import {
    ArrowPathIcon,
    CheckIcon,
    ChevronDoubleLeftIcon,
    ChevronUpDownIcon,
    ClipboardDocumentCheckIcon,
    ClipboardDocumentIcon
} from "@heroicons/react/20/solid";
import {Link} from "react-router-dom";
import {UseMutationResult} from "@tanstack/react-query";


export function Button({onClick, children, disabled, className}: {onClick:() => void, children:ReactNode, disabled?:boolean, className?:string}) {
    let buttonTailwind = "rounded inline-block px-3 py-1 text-sm font-semibold text-gray-700 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-blue-500";

    if (disabled) {
        buttonTailwind += " bg-gray-400"
    } else {
        buttonTailwind += " bg-blue-100 drop-shadow hover:bg-blue-200 hover:text-gray-900"
    }

    if (className !== undefined) {
        buttonTailwind += " " + className;
    }

    return<button
        type="button"
        disabled={disabled}
        className={buttonTailwind}
        onClick={onClick}
    >
        {children}
    </button>
}

export function MutationButton<TData, TError, TVariables, TContext>({mutation, children, then, className, disabled}: {
        mutation: UseMutationResult<TData, TError, TVariables, TContext>,
        children: ReactNode,
        then?: () => void,
        className?: string,
        disabled?: boolean
    }) {
    let buttonTailwind = "rounded inline-block px-3 py-1 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-blue-500 relative";

    // @ts-ignore
    let mutateArg : TVariables = undefined;

    let onClick = () => mutation.mutate(mutateArg);
    if (then !== undefined) {
        onClick = () => mutation.mutateAsync(mutateArg).then(then)
    }

    if (mutation.status === "pending") {
        buttonTailwind += " bg-gray-100 text-gray-400 animation-pulse"
    } else if (disabled) {
        buttonTailwind += " bg-gray-400"
    } else {
        buttonTailwind += " bg-blue-100 text-gray-700 drop-shadow hover:bg-blue-200 hover:text-gray-900"
    }

    if (className !== undefined) {
        buttonTailwind += " " + className;
    }

    return <button
        type="button"
        disabled={mutation.status === "pending" || disabled}
        className={buttonTailwind}
        onClick={onClick}
    >
        <div className="relative">
            {children}
        </div>
        {mutation.status === "pending" ?
            <span className="absolute top-1 left-0 w-full h-full text-center align-middle flex">
                <ArrowPathIcon className="h-5 w-5 text-blue-900 mx-auto animate-spin" aria-hidden="true"/>
            </span>
            : <></>}
    </button>
}

export function SubmitButton({children, disabled, className}: {children:ReactNode, disabled?:boolean, className?:string}) {
    let buttonTailwind = "rounded inline-block px-3 py-1 text-sm font-semibold text-black-950 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-200";

    if (disabled) {
        buttonTailwind += " bg-gray-400 hover:bg-blue-200"
    } else {
        buttonTailwind += " bg-blue-100 drop-shadow-xl"
    }

    if (className !== undefined) {
        buttonTailwind += " " + className;
    }

    return<button
        type="submit"
        disabled={disabled}
        className={buttonTailwind}
    >
        {children}
    </button>
}

export function IconButton({children, className, onClick} : {children:ReactNode, className?:string, onClick:() => void}) {
    return <button
        type="button"
        className={"rounded-full bg-blue-600 p-1 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"  + (className === undefined ? "" : className)}
        onClick={onClick}
    >
        {children}
    </button>
}

export function IconOutlineButton({children, className} : {children:ReactNode, className?:string}) {
    return <button
        type="button"
        className={"rounded-full bg-white border-2 shadow-sm hover:bg-blue-200 hover:border-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 " + (className === undefined ? "" : className)}
    >
        {children}
    </button>
}

export let baseTextTailwind = "border-solid border-2 border-gray-200 bg-white m-0.5 hover:bg-amber-50 px-1 rounded";

export function TextInput({value, setValue, className}: {value:string, setValue:(newValue:string) => void, className?:string}) {
    return <input
        type="text"
        className={className === undefined ? baseTextTailwind : baseTextTailwind + " " + className}
        value={value}
        onChange={e => setValue(e.target.value)}/>;
}

export function PasswordInput({value, setValue, className}: {value:string, setValue:(newValue:string) => void, className?:string}) {

    return <input
        type="password"
        className={className === undefined ? baseTextTailwind : baseTextTailwind + " " + className}
        value={value}
        onChange={e => setValue(e.target.value.replace(/•*/i, ""))}/>;
}

export function TextArea({value, setValue, className}: {value:string, setValue:(newValue:string) => void, className?:string}) {
    return <div className={className === undefined ? "mr-1" : "mr-1 " + className}>
        <textarea
            rows={5}
            className={"block w-full rounded-md border-2 border-gray-200 m-0.5 px-2 p-1 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 hover:bg-amber-50"}
            onChange={e => setValue(e.target.value)}
            value={value}
        />
    </div>;
}

export function Checkbox({value, setValue}: {value:boolean, setValue:(newValue:boolean) => void}) {

    return (
        <Switch
            checked={value}
            onChange={setValue}
            className="group relative inline-flex h-6 w-11 m-1 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-300 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 data-[checked]:bg-blue-600"
        >
            <span className="sr-only">Use setting</span>
            <span className="pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5">
        <span
            aria-hidden="true"
            className="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
        >
          <svg fill="none" viewBox="0 0 12 12" className="h-3 w-3 text-gray-500">
            <path
                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
          </svg>
        </span>
        <span
            aria-hidden="true"
            className="absolute inset-0 flex h-full w-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
        >
          <svg fill="currentColor" viewBox="0 0 12 12" className="h-3 w-3 text-blue-600">
            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
          </svg>
        </span>
      </span>
        </Switch>
    );
}

export function DropDown({value, setValue, choices, className} : {value:string, setValue:(value:string) => void, choices:string[], className?:string}) {
    let name = undefined;
    if (choices !== undefined && choices !== null && value !== undefined && value !== null) {
        name = choices.find(choice => choice.toUpperCase() === value.toUpperCase());
    }
    if (name === undefined) {
        name = "";
    }
    return <div className={className === undefined ? "" : className}>
        <Listbox value={value} onChange={setValue}>
            <div className="relative pr-1">
                <ListboxButton className={"relative w-full cursor-default text-left py-0 " + baseTextTailwind}>
                    <span className="block truncate min-h-[1.75em]">{name}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"><ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" /></span>
                </ListboxButton>

                <ListboxOptions
                    transition
                    className="absolute z-10 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg border-2 border-gray-200 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                >
                    {choices.map((choice) => (
                        <ListboxOption
                            key={choice}
                            value={choice}
                            className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-blue-600 data-[focus]:text-white"
                        >
                            <span className="block truncate pl-0.5 min-h-[1rem] font-normal group-data-[selected]:font-semibold">{choice}</span>

                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                <CheckIcon aria-hidden="true" className="h-5 w-5" />
                            </span>
                        </ListboxOption>
                    ))}
                </ListboxOptions>
            </div>
        </Listbox>
    </div>
}

export function Header({children, className} : {children:ReactNode, className?:string}) {
    return <h1 className={"text-xl bg-blue-50 p-4 px-6 mb-6 " + (className === undefined ? "" : className)}>{children}</h1>
}

export function Subheader({children, className} : {children:ReactNode, className?:string}) {
    return <h2 className={"text-xl border-b border-blue-100 p-2 px-6 " + (className === undefined ? "" : className)}>{children}</h2>
}

export function Section({children} : {children: ReactNode}) {
    return <div className="p-2 px-6">
        {children}
    </div>
}

export function BackButton({link} : {link:string}) {
    return <Link className="bg-white border-2 border-blue-300 hover:bg-gray-100 hover:border-blue-600 rounded-full mr-2" to={link}><ChevronDoubleLeftIcon className="inline -mt-1 h-6 w-6"/></Link>
}

export function TextLink({children, to} : {children:ReactNode, to:string}) {
    return <Link to={to} className="font-semibold text-blue-900 hover:text-blue-700">{children}</Link>
}

export function TextWithLineBreaks({text, className} : {text: string, className?:string}) {
    const textWithBreaks = text.split('\n').map((text, index) => (
        <React.Fragment key={index}>
            {text}
            <br />
        </React.Fragment>
    ));

    return <div className={className}>{textWithBreaks}</div>;
}

export function CopyBox({text, className} : {text: string, className?:string}) {
    const [copied, setCopied] = useState(false);

    return <div className="cursor-pointer group inline" onClick={() => {navigator.clipboard.writeText(text).then(() => setCopied(true))}}>
        <div className={"inline rounded-l-lg p-1 border group-hover:border-gray-400 group-hover:bg-gray-200 " + (className===undefined?"":className)}>{text}</div>
        <div className="inline rounded-r-lg p-1 border border-l-0 bg-blue-100 group-hover:border-gray-400 group-hover:bg-blue-300">
            {copied ? <ClipboardDocumentCheckIcon className="h-5 w-5 inline"/> : <ClipboardDocumentIcon className="h-5 w-5 inline"/>}
        </div>
    </div>
}
