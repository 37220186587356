import {ReactNode} from "react";
import {Helmet} from "react-helmet";

export function DialogPage({children, title} : {children: ReactNode, title:string}) {
    return <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <div className="h-full pt-16 bg-blue-50">
            <div className="max-w-[800px] min-h-[400px] p-4 bg-white border-2 border-blue-500 rounded-xl mx-auto">
                {children}
            </div>
        </div>
    </>;
}
