import {Elements, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js/pure';
import {FormEvent} from "react";
import {useClient} from "../../client/AuthClient";
import {MutationButton, SubmitButton} from "../Base";
import {formatFullDate, getEnv} from "../Utils";
import {useMutation, useQuery} from "@tanstack/react-query";

loadStripe.setLoadParameters({advancedFraudSignals: false});
const stripePromise = loadStripe(getEnv(process.env.REACT_APP_STRIPE_CLIENT_KEY));

function PaymentForm({clientSecret}: {clientSecret: string}) {

    const stripe = useStripe();
    const elements = useElements();

    async function handleSubmit(event: FormEvent) {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }
        let options = {
            elements,
            confirmParams: {
                return_url: process.env.REACT_APP_HOSTNAME + "settings",
            },
        };

        const result = clientSecret.startsWith("seti_") ? await stripe.confirmSetup(options) : await stripe.confirmPayment(options);


        if (result.error) {
            console.log(result.error.message);
        } else {
            console.log("Success?")
        }
    }
    return <form onSubmit={handleSubmit}>
        <PaymentElement />
        <SubmitButton className="float-right mt-4">Submit</SubmitButton>
    </form>
}

function CreateSubscription() {
    const {client} = useClient();

    const newSubscriptionQuery = useQuery({
       queryKey: ['newSubscription'],
       queryFn: () => client.NewSubscription()
    });

    console.log(newSubscriptionQuery.data)
    if (newSubscriptionQuery.data === undefined) {
        return <></>
    }
    return <Elements stripe={stripePromise} options={{
        clientSecret: newSubscriptionQuery.data.clientSecret
    }}>
        <PaymentForm clientSecret={newSubscriptionQuery.data.clientSecret}/>
    </Elements>;
}

export default function Stripe() {
    const {client} = useClient();

    const getSubscriptionQuery = useQuery({
        queryKey: ['getSubscription'],
        queryFn: () => client.GetSubscriptionInfo()
    });

    const cancelSubscriptionQuery = useMutation({
        mutationFn: () => client.CancelSubscription()
    })

    if (getSubscriptionQuery.data === undefined) {
        return <></>
    }
    if (getSubscriptionQuery.data?.subscriptionId === undefined || getSubscriptionQuery.data.subscriptionId === null) {
        return <div className="overflow-hidden py-4">
            <CreateSubscription/>
        </div>
    }

    function subscriptionDescription(subcriptionInfo: {
        subscriptionId: string,
        createdAt: number,
        next: number,
        cancelledAt: number
    }) {
        if (subcriptionInfo.cancelledAt !== 0) {
            return <div>
                Cancelled subscription, started at {formatFullDate(subcriptionInfo.createdAt)}, cancelled at {formatFullDate(subcriptionInfo.cancelledAt)}, will not renew on {formatFullDate(subcriptionInfo.next)}.
            </div>
        }
        return <div>
            Active subscription, started at {formatFullDate(subcriptionInfo.createdAt)}, next renewal on {formatFullDate(subcriptionInfo.next)}.
        </div>
    }

    return <div>
        {subscriptionDescription(getSubscriptionQuery.data)}
        <MutationButton className="float-right" mutation={cancelSubscriptionQuery}>Cancel Subcription</MutationButton>
    </div>;
};
