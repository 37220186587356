import {DialogPage} from "../../ui/DialogPage";
import {useNavigate, useSearchParams} from "react-router-dom";
import {ArrowPathIcon, CheckCircleIcon, LinkSlashIcon} from "@heroicons/react/20/solid";
import {useEffect, useState} from "react";
import {useClient} from "../../client/AuthClient";

export default function VerifyEmail() {
    const navigate = useNavigate();
    const {client} = useClient();
    const [searchParams] = useSearchParams();

    const [error, setError] = useState<String | undefined | null>();

    let code = searchParams.get("code");

    useEffect(() => {
        if (error == null) {
            const timeoutId = setTimeout(() => {
                navigate("/");
            }, 5000);

            return () => clearTimeout(timeoutId);
        }
    }, [navigate, error]);

    useEffect(() => {
        if (code !== null) {
            client.VerifyEmail({code: code}).then((result) => {
                if (result.error === null) {
                    setError(null);
                } else {
                    setError(result.error);
                }
            });
        }
    }, [client, code]);

    function verificationStatus() {
        if (code === null) {
            return <>
                <div>Invalid Link</div>
                <LinkSlashIcon className="h-16 w-16 mx-auto m-8" aria-hidden="true"/>
            </>;
        } else if (error === undefined) {
            return <>
                <div>Verifying...</div>
                <ArrowPathIcon className="h-16 w-16 mx-auto m-8 animate-spin" aria-hidden="true"/>
            </>;
        } else if (error === null) {
            return <>
                <div>Email Verified!</div>
                <div>You'll be redirected in 5 seconds.</div>
                <CheckCircleIcon className="h-16 w-16 mx-auto m-8" aria-hidden="true"/>
            </>;
        } else {
            return <>
                <div>Invalid Code</div>
                <span className="text-red-700">{error}</span>
                <LinkSlashIcon className="h-16 w-16 mx-auto m-8" aria-hidden="true"/>
            </>;
        }
    }

    return <DialogPage title="Verifying email">
        <div className="text-center text-xl p-4">
            {verificationStatus()}
        </div>
    </DialogPage>
}
