import {createSearchParams, useNavigate} from "react-router-dom";
import {BasePage} from "../ui/Page";
import {Subheader, TextLink} from "../ui/Base";
import {getReplyInfo} from "./Inbox";
import {formatShortDateTime} from "../ui/Utils";
import Address from "../ui/Address";
import {SingleLinePlaceholder, SingleLinePlaceholderBlank} from "../ui/Placeholders";
import {EmailData} from "../client/Client";
import {useClient} from "../client/AuthClient";
import {useQuery} from "@tanstack/react-query";

function replyLabel(text: String) {
    return <span className="font-bold">{text}</span>
}

function MailPreview () {
    const navigate = useNavigate();
    const {client} = useClient();

    const emailRecordQuery = useQuery({
        queryKey: ["EmailRecord"],
        queryFn: () => {
            const inputData = {} as {
                before: string,
            };
            return client.GetEmailRecords(inputData).then((result) => {
                let emailData: EmailData[] = Array(10).fill(undefined);
                for (let i = 0; i < result.emailData.length && 1 < 10; i++) {
                    emailData[i] = result.emailData[i];
                }
                return emailData;
            })
        },
        initialData: Array(10).fill(null)
    });

    function goToMessage(messageId: string) {
        navigate({
            pathname: "/message",
            search: createSearchParams({
                messageId: btoa(messageId)
            }).toString()
        });
    }

    return <table className="min-w-full divide-y divide-gray-300">
        <tbody className="bg-white">
        {emailRecordQuery.data.map((email, index) => (
            <tr key={index} className="even:bg-gray-50 mx-4">
                <td className="whitespace-nowrap py-1 pl-4 pr-3 text-sm font-medium text-gray-900 w-[100px] sm:pl-3">
                    {email === undefined ? <SingleLinePlaceholderBlank/> : (email === null ? <SingleLinePlaceholder/> : formatShortDateTime(email.timestamp))}
                </td>
                <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-600">
                    <div className="inline-block w-[200px] overflow-x-clip">{email === undefined ? <SingleLinePlaceholderBlank/> : (email === null ? <SingleLinePlaceholder/> : <Address address={email.from}/>)}</div>
                </td>
                <td className="whitespace-nowrap px-3 py-1 text-sm w-[150px]">
                    <div className="flex items-center">
                        <div className="flex-grow"/>
                        {email === undefined ? <SingleLinePlaceholderBlank/> : (email === null ? <SingleLinePlaceholder/> : getReplyInfo(email.messageId, email.replyInfo, goToMessage))}
                    </div>
                </td>
            </tr>
        ))}
        </tbody>
    </table>
}

function MailboxInfo() {
    const {client} = useClient();

    const loadMailboxQuery = useQuery({
        queryKey: ['MailboxSettings'],
        queryFn: () => client.GetMailboxSettings()
    });

    if (loadMailboxQuery.data === undefined) {
        return <>
            <div>My Mailbox</div>
            <div className="flex">{replyLabel("Email:")} <div className="flex-grow ml-2"><SingleLinePlaceholder/></div></div>
            <div className="flex">{replyLabel("Action set:")} <div className="flex-grow ml-2"><SingleLinePlaceholder/></div></div>
            <div className="flex">{replyLabel("Agent name:")} <div className="flex-grow ml-2"><SingleLinePlaceholder/></div></div>
            <div className="flex">{replyLabel("Status:")} <div className="flex-grow ml-2"><SingleLinePlaceholder/></div></div>
        </>
    }

    return <>
        <div>My Mailbox</div>
        <div>{replyLabel("Email:")} {loadMailboxQuery.data.username}</div>
        <div>{replyLabel("Action set:")} {loadMailboxQuery.data.actionSet}</div>
        <div>{replyLabel("Agent name:")} {loadMailboxQuery.data.agentName}</div>
        <div>{replyLabel("Status: ")}
            {loadMailboxQuery.data.active?
                <span className="text-green-900 bg-green-300 px-2 font-semibold rounded">Active</span> :
                <span className="text-gray-800 bg-gray-300 px-2 rounded">Inactive</span>
            }</div>
    </>
}

export default function Home () {
    return <BasePage title="ReplyBot.io">
        <div className="max-w-[1094px] mx-auto">
            <div className="my-10">
                <h1 className="text-6xl font-semibold text-center">
                    <div className="inline-block w-64 text-right"><img alt="replybot logo" className="inline h-28 ml-1 -mt-1" src={process.env.PUBLIC_URL + '/logo192.png'} /></div>
                    <span className="text-[#004080]">Reply</span><span className="text-[#D32F2F]">Bot</span><span className="text-blue-700">.io</span>
                    <img className="inline w-64" src={process.env.PUBLIC_URL + '/img/HelloRobot.webp'} alt="Hello Robot"/>
                </h1>
            </div>
            <div className="grid grid-cols-3 gap-4 p-4">
                <div className="border rounded-lg rounded-tl-2xl bg-white">
                    <Subheader>Check out our docs:</Subheader>
                    <div className="m-4">
                        Start by checking out our <TextLink to={process.env.REACT_APP_DOCS+"setup_replybot"}>getting started guide</TextLink> or our <TextLink to={process.env.REACT_APP_DOCS+"example_use_case"}>example uses cases.</TextLink> <br/>
                        Or if your having issues check out <TextLink to={process.env.REACT_APP_DOCS+"frequently_asked_questions"}>our FAQ.</TextLink>
                    </div>
                </div>
                <div className="col-span-2 rounded-lg rounded-tr-2xl border bg-white">
                    <Subheader>Useful pages:</Subheader>
                    <ul className="list-disc list-inside m-4">
                        <li><TextLink to={"/inbox"}>Inbox: </TextLink> Find a record of emails that have been processed by replybot.</li>
                        <li><TextLink to={"/send"}>Send</TextLink> Send new emails. Custom reply actions coming soon!</li>
                        <li><TextLink to={"/actions"}>Actions</TextLink> Configure reply bot automations.</li>
                        <li><TextLink to={"/settings"}>Settings</TextLink> Setup mailboxes and billing here.</li>
                    </ul>
                </div>
                <div className="border col-span-2 rounded-lg rounded-bl-2xl bg-white overflow-hidden">
                    <Subheader>Recent emails:</Subheader>
                    <MailPreview/>
                </div>
                <div className="border col-span-1 rounded-lg rounded-br-2xl bg-white">
                    <Subheader>Mailbox:</Subheader>
                    <div className="p-4">
                        <MailboxInfo/>
                    </div>
                </div>
            </div>
        </div>
    </BasePage>
}
