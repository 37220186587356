import Page from "../../ui/Page";
import {Button, Header, MutationButton, Section, TextInput, TextLink} from "../../ui/Base";
import {useClient} from "../../client/AuthClient";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {ModalDialog} from "../../ui/ModalDialog";
import {useMutation, useQuery} from "@tanstack/react-query";

export default function ActionList() {
    const navigate = useNavigate();
    const {client} = useClient();

    const [newActionModal, setNewActionModal] = useState(false);
    const [newAction, setNewAction] = useState<string>("");

    const listActionSetQuery = useQuery({
        queryKey: ['ActionSets'],
        queryFn: () => client.ListActionSets().then(result => result.actionSets),
        initialData: []
    });

    const createActionSetQuery = useMutation({
        mutationKey: ['ActionSets'],
        mutationFn: () => client.CreateActionSet({
            actionSetName: newAction
        }).then(() => {
            navigate("/actions/" + newAction)
        })
    });

    return <Page title="Action Sets">
        <Header>Your Action sets:</Header>
        <Section>
            <ul className="list-disc list-inside mb-4">
            {listActionSetQuery.data?.map(function(ele) {
                return <li><TextLink to={"/actions/" + ele}>{ele}</TextLink></li>;
            })}
            </ul>

            <Button onClick={() => setNewActionModal(true)}>New Action Set</Button>
        </Section>
        <ModalDialog className={"max-w-[500px]"} isOpen={newActionModal} requestClose={() => setNewActionModal(false)} label={"New Action Set"}>
            <TextInput value={newAction === undefined ? "" : newAction} setValue={setNewAction}/>
            <MutationButton mutation={createActionSetQuery}>New Action Set</MutationButton>
        </ModalDialog>
    </Page>
}
